// axiosConfig.js
import axios from 'axios';

const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // You can modify the request config here
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Return a successful response
    return response;
  },
  (error) => {
    // Handle 401 errors and redirect to the login page
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
      // window.location.href = '/login';
    }

    // Return any other errors
    return Promise.reject(error);
  }
);

export default instance;