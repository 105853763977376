import { Heading, Link, Text, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";
import "./BeautyOffers.css";
import {
  ChevronRightIcon,
  AddIcon,
  WarningIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductService from "../../../apis/product.api";
const placeholder = require("./placeholder.jpg");

const BeautyOffers = ({addToCartHandler}) => {
  const topbar = useSelector((state) => state.topBar).topbar;
    const lang = useSelector((state) => state.siteLanguage).lang;
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [reload, setReload] = useState(true);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [itemNumber, setItemNumber] = useState(5);

    const navigate = useNavigate();
    const chevronWidth = 8;
  
    const productApiService = new ProductService();
  
  
    const getData = async () => {
      const res = await productApiService.getOfferProducts();
      setProducts(res)
      setReload(false);
    }
    useEffect(() => {
        getData();
    }, [lang]);

    useEffect(() => {
      const windowWidth = window.innerWidth;
      var numberOfCards = 6;
      if (windowWidth < 640) {
        numberOfCards = 3;
      }
      setItemNumber(numberOfCards);
    }, [window.innerWidth]);

  return (
    <div
      // style={{ padding: `${chevronWidth}px 2px` }}
      className="beauty_container"
    >
      <Heading as="h4" size="md">
        {t("beauty_offers")}
      </Heading>

      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={itemNumber}
        gutter={8}
        leftChevron={
          <div className="beauty_carousel_btn_box_L">
            {" "}
            <ChevronLeftIcon boxSize={10} color="white" />{" "}
          </div>
        }
        rightChevron={
          <div className="beauty_carousel_btn_box_R">
            {" "}
            <ChevronRightIcon boxSize={10} color="white" />{" "}
          </div>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {products.map((item, index) => {
          return (
            <div className="beauty_item" key={item.image}>
              <div className="beauty_item_img_box">
                {}
                <img alt="test product" src={item.image !== "" ? item.image : placeholder} />
              </div>
              <div className="beauty_item_txt_box">
                <Heading as="h6" size="xs" mt="-5px">
                  {item.name}
                </Heading>
                <Text fontSize="sm" ml="0px" mt="5px" className="beauty-item-description">
                  {item.description}
                </Text>
                
                <Box className="add-to-cart-button-main">
                  <button
                    onClick={(e)=>addToCartHandler(item)}
                    style={{color: topbar?.top_bar_text_color, background: topbar?.top_bar_color}}
                    // onMouseEnter={}
                    // onMouseLeave={(e) => {e.target.style.backgroundColor = ''; }}
                    className="add-to-cart-button">{t("quick_buy")}</button>
                </Box>
              </div>
            </div>
          );
        })}
      </ItemsCarousel>
      <button style={{float: 'right'}} onClick={()=>navigate("/products/hooks/offer")}> {t("see_all")}</button>

      <hr style={{ margin: "50px 0" }} />
    </div>
  );
};
export default BeautyOffers;
