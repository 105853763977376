import { BellIcon, EmailIcon, Search2Icon, ArrowUpIcon } from "@chakra-ui/icons";
import { Button, Heading, Text, Box } from "@chakra-ui/react";
import {useLocation} from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from 'react-toastify';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { removeUser, updateTopbar } from "../../store/actions/productLike.action";
import { useTranslation } from 'react-i18next';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CloseIcon } from '@chakra-ui/icons';

import productApiService from "../../apis/product.api";
import BrandMenu from "./BrandMenu";
import ProductType from "./ProductType";
import SearchProductListCard from "./SearchProductListCard";
import LanguageSwitcher from "./LanguageSwitcher";
import "./Navbar.css";
const logo = require("./manchi-logo.png");
const contact_icon = require("./contact.png");

const Navbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.user).user;

  const lang = useSelector((state) => state.siteLanguage).lang;
  const loginToken = localStorage.getItem("token");
  const cart = useSelector((state) => state.cart).cart;

  const [auth, setAuth] = useState(false);
  const [topBar, setTopBar] = useState(undefined);
  const [wishlistHover, setWishlistHover] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultPopup, setSearchResultPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [mobileMenuShow, setMobileMenuShow] = useState(false);

  const apiProductService = new productApiService();

  function routeLogin() {
    navigate("/login");
  }

  function routeseller() {
    navigate("/seller/addproduct");
  }

  const fetchToBar = async () => {
    const result = await apiProductService.getTopBarDetails();
    setTopBar(result);
    dispatch(updateTopbar(result));
  }

  const fetchdata = async () => {
    const cartObj = await apiProductService.getCart();
    dispatch({type: 'UPDATE_CART',payload: cartObj});
  }


  useEffect(() => {
    if(loginToken){
      fetchdata();
    }
  },[]);

  useEffect(() => {
    fetchToBar();
  },[lang]);
  
  useEffect(() => {
    if(mobileMenuShow){
      document.body.classList.add("no-scroll");
    }
    if(!mobileMenuShow){
      document.body.classList.remove("no-scroll");
    }
  },[mobileMenuShow]);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const location = useLocation();

  const signouthandler = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
      dispatch(removeUser());
      dispatch({ type: "REMOVE_CART"});
      toast.success(t("logged_out"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
      navigate("/login");
  }

  const handleProductSearch = async (e) => {
      let value = e.target.value;
      const res = await apiProductService.searchProducts(value);
      setSearchResults(res);
      setSearchResultPopup(true);
  }

  return (
    <>
    {topBar ? 
      <div id="nav1" style={{background:`${topBar.top_bar_color}`, color: `${topBar.top_bar_text_color}`}}>
        <h3>{topBar.top_bar_text}</h3>
      </div> : null}

      <div id="nav2">
        <div>
          
          
          <div className="logo-main">
            <div className="select-language-select-main">
              <LanguageSwitcher lang={lang} />
            </div>
            <a href="#" className="mobile-menu-button" onClick={()=>setMobileMenuShow((prev) => !prev)}><HamburgerIcon /></a>
            <Link to="/" ><img src={logo} className="logo-image"/></Link>
          </div>
          <input type="text" className="search-bar-input" onChange={(e)=> handleProductSearch(e)} style={{paddingLeft: "10px"}} name="" id="" placeholder="🔍 Search" />
          
          {searchResultPopup ? 
            <SearchProductListCard 
              setSearchResultPopup={setSearchResultPopup} 
              setSearchResults={setSearchResults} 
              searchResults={searchResults} /> 
          : null }
<div className="select-language-select-mobile">
            <LanguageSwitcher lang={lang} />

          </div>
          <div className="dropdown user-account-dropdown">
          
          {currentUser?.id ? <><span>
              <img
                src="https://cdn-icons-png.flaticon.com/512/94/94428.png"
                alt=""
              />
            </span> 
                    
              <p className="user-name">
              <Text marginLeft={"0px"}>{t('hi')} {currentUser?.first_name}</Text>
            </p> 
        
           <div className="dropdown-content">

              <div className="dropdown_container1">

                <div className="dropdown_container1_box">
                  <div
                    className="dropdown_container1_sub_box1"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <img
                        src="https://www.sephora.com/img/ufe/icons/me-active.svg"
                        alt="img"
                      />
                    </div>
                    <div>
                     <h4>{t('hi')} {currentUser?.first_name} 🎉  </h4>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "5px 0" }} />
                <div className="dropdown_container1_sub_box2">
                  <div>
                    <img
                      id="signin_dropdown_img"
                      src="https://www.sephora.com/img/ufe/icons/beauty-traits.svg"
                      alt="img"
                    />
                  </div>
                  <div>
                    <h4><Link to="/pernalizations">{t('beauty_preferences')} </Link> </h4>
                    <p>{t('beauty_preferences_text')}</p>
                  </div>
                </div>
                <hr style={{ margin: "5px 0" }} />
                <div className="dropdown_container1_sub_box2">
                  <div>
                    <img
                      id="signin_dropdown_img"
                      src="https://www.sephora.com/img/ufe/icons/subscription.svg"
                      alt="img"
                    />
                  </div>
                  <div>
                    <h4><Link to="/mypurchases">{t("buy_it_again")}</Link></h4>
                    <p>{t("buy_it_again_text")}</p>
                  </div>
                </div>
                <hr style={{ margin: "5px 0" }} />
                <div className="dropdown_container1_sub_box2">
                  <div>
                    <img
                      id="signin_dropdown_img"
                      src="https://www.sephora.com/img/ufe/icons/track.svg"
                      alt="img"
                    />
                  </div>
                  <div>
                    <h4>
                      <Link to="/myorders">{t("orders")}</Link>
                    </h4>
                    <p>{t("orders_button_text")}</p>
                  </div>
                </div>
                <hr style={{ margin: "5px 0" }} />
                <div className="dropdown_container1_sub_box2">
                  <div>
                    <img
                      id="signin_dropdown_img"
                      src="https://www.sephora.com/img/ufe/icons/heart.svg"
                      alt="img"
                    />
                  </div>
                  <div>
                    <h4>
                      <Link to="/mywishlists">{t("loves")}</Link>
                    </h4>
                    <p>{t("loves_text")}</p>
                  </div>
                </div>
                <hr style={{ margin: "5px 0" }} />
                <div className="dropdown_container1_sub_box2">
                  <div>
                    <img
                      id="signin_dropdown_img"
                      src="https://www.sephora.com/img/ufe/icons/account.svg"
                      alt="img"
                    />
                  </div>
                  <div>
                    <h4>
                      <Link to="/myaccount">{t("account_settings")}</Link>
                    </h4>
                    <p>{t("account_setting_btn_text")}</p>
                  </div>
                </div>
              </div>
            </div> </>: null}
          </div> 
        </div>
        
        <div>
          
          {/* <img
            src="https://cdn-icons-png.flaticon.com/512/7174/7174950.png"
            alt=""
            className="search"
          /> */}
          <Box id="btn">
              {token ? (
                <div>
                      <Button variant="ghost" onClick={signouthandler}>
                  {t("signout")}
                </Button>
                </div>
              ) : (
                <Button variant="ghost" onClick={routeLogin}>
                  {t("signin")}
                </Button>
              )}
          </Box>
          <Box className="nav-right-buttons">
         
            <Link 
              to="/mywishlists" 
              className="wishlist-link"
              onMouseEnter={() => setWishlistHover(true)}
              onMouseLeave={() => setWishlistHover(false)}
            >
              {" "}
              {location.pathname === "/mywishlists" || wishlistHover ?
              <img
              src="https://cdn-icons-png.flaticon.com/128/535/535234.png"
              alt=""
              className="wishlist-image-black search"
            /> :
              <img
                src="https://cdn-icons-png.flaticon.com/512/7777/7777990.png"
                alt=""
                className="wishlist-image-white search"
              /> }
              
            </Link>
            <Link to="/basket" style={{position: "relative"}}>
              <Text className="nav-notify-icon" background={topBar?.top_bar_color} top={"-7px"} position={"absolute"}>{cart?.items.length}</Text>
              {" "}
              <img
                src="https://cdn-icons-png.flaticon.com/512/743/743131.png"
                alt=""
                className="search"
              />
            </Link>
            
          </Box>
        </div>
      </div>
      <div id="nav5" className={`${mobileMenuShow ? "show-mobile-menu": ''}`}>
      <div className="main-sidebar-close-icon">
        <CloseIcon boxSize={5} color="white" onClick={()=>setMobileMenuShow((prev)=> !prev)}/>
      </div>
        <ul>
        
          <BrandMenu {...topBar} />
          <ProductType {...topBar} lang={lang} />
          <li >
              <Link  className="nav5-li-link" to="/subscription"> Subscription <span className="arrow-up"></span></Link>
          </li>
        </ul>
      </div>
    
    </>
  );
};

export default Navbar;
