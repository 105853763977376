import { Heading, Link, Text, Box } from "@chakra-ui/react";
import axios from 'axios';
import React, { useContext, useEffect, useState} from 'react'
import ItemsCarousel from "react-items-carousel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProductService from "../../../apis/product.api";
import "./SellingFast.css";
import {
  ChevronRightIcon,
  AddIcon,
  WarningIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
const SellingFast = ({addToCartHandler}) =>  {
  const topbar = useSelector((state) => state.topBar).topbar;
  const lang = useSelector((state) => state.siteLanguage).lang;
  const [products, setProducts] = useState([]);
  const [reload, setReload] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [itemNumber, setItemNumber] = useState(6);

  const { t } = useTranslation();
  const chevronWidth = 8;

  const productApiService = new ProductService();
  const navigate = useNavigate();

  const getData = async () => {
    
    const res = await productApiService.getBestSallerProducts();
    setProducts(res)
    setReload(false);
  }
  useEffect(() => {
      getData();
  }, [lang]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    var numberOfCards = 6;
    if (windowWidth < 640) {
      numberOfCards = 2;
    }
    setItemNumber(numberOfCards);
  }, [window.innerWidth]);

  return (
    <div
      // style={{ padding: `${chevronWidth}px 2px` }}
      className="dropped_container selling-fast"
    >
      <Heading as="h4" size="md" >
        {t("best_selling")}
      </Heading>

      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={itemNumber}
        gutter={8}
        leftChevron={
          <div className="dropped_carousel_btn_box_L">
            {" "}
            <ChevronLeftIcon boxSize={10} color="white" />{" "}
          </div>
        }
        rightChevron={
          <div className="dropped_carousel_btn_box_R">
            {" "}
            <ChevronRightIcon boxSize={10} color="white" />{" "}
          </div>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {products.map((item, index) => {
          return (
            <div className="dropped_item" key={item.image}>
              <div className="dropped_item_img_box">
                <img alt="test product" src={item.image} />
                <button className="dropped_item_img_btn">
                  <Heading as="h6" size="xs" p="5px">
                  <Link onClick={() => navigate(`/product/${item.slug}`)}>
                  {t("quicklook")}
                    </Link>
                  </Heading>
                </button>
              </div>
              <div
                style={{ backgroundColor: `${item.color}`}}
                className="dropped_item_txt_box"
              >
                <Heading as="h6" size="xs" mt="-5px">
                  {item.name}
                </Heading>
                <Text fontSize="sm" ml="0px" mt="5px">
                  {item.description}
                </Text>
                <Box className="add-to-cart-button-main">
                  <button
                    onClick={(e)=>addToCartHandler(item)}
                    style={{color: topbar?.top_bar_text_color, background: topbar?.top_bar_color}}
                    // onMouseEnter={}
                    // onMouseLeave={(e) => {e.target.style.backgroundColor = ''; }}
                    className="add-to-cart-button">{t("quick_buy")}</button>
                </Box>
              </div>
              
            </div>
          );
        })}
      </ItemsCarousel>
      <hr style={{ margin: "50px 0" }} />
    </div>
  );
};
export default SellingFast;
